// RESPONSIVE
$phone-size: 700px;
$intermediate-size: 1200px;

// Phone + intermediate
@media only screen and (max-width: $phone-size) {
    .new-transfer-container {
        width: 80vw;
        .all-inputs-container .input-container .date-inputs input {
            width: 42.5%!important;
        }
    }
}

// Phone + intermediate
@media only screen and (min-width:  $phone-size) and (max-width: $intermediate-size) {
    .new-transfer-container {width: 60vw;}
}


// Larger
@media only screen and (min-width: $intermediate-size) {
    .new-transfer-container {width: 30vw;}
}

.new-transfer-container {
    display: flex;
    flex-direction: column;
    border-radius: 11px;
    background-color: rgb(33,33,33);
    border-radius: 25px;
    padding: 3vh;

    .new-transfer-title {
        text-align: center;
        margin: 0;
        font-weight: bold;
        font-size: 1.7em;
    }
    
    .all-inputs-container {
        margin: 2vh 0 2vh 0;
        display: flex;
        flex-direction: column;

        .input-container {
            margin-top: 3vh;
            display: flex;
            flex-direction: column; 
            font-weight: bold;

            input {
                margin-top: 1vh;
                background-color: white;
                border-width: 0;
                border-radius: 5px;
                padding: 1vh;
            }

            .date-inputs {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                input {
                    width: 45%;
                }
            }
        }
    }
    
    .buttons-container {
        margin-top: 3vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        button {
            width: 47.5%;
        }
    }
    
}

// FONTS

@font-face {
	font-family: 'Oxygen';
	src: local('Oxygen'), url(../fonts/Oxygen.ttf) format('truetype');
}

@font-face {
	font-family: 'Ubuntu';
	src: local('Ubuntu'), url(../fonts/Ubuntu.ttf) format('truetype');
}

// RESPONSIVE
$phone-size: 700px;
$intermediate-size: 1200px;

// Phone
@media only screen and (max-width: $phone-size) {
	body {
		background-size: cover;
		.header {
			h2 {font-size: 1.3em; width: 50%;}
			.network, a {width: 15vw;}
			a img {height: 6vh;}
			.network {font-size: 0.8em;}
		}
		.footer {font-size: 0.8em;}
	} 
}

// intermediate
@media only screen and (min-width: $phone-size) and (max-width: $intermediate-size) {
	body {
		.header {
			h2 {font-size: 2em;}
			.network, a {width: 11vw;}
			a img {height: 4.4vw;}
			.network {font-size: 0.9em;}
		}
	} 
}

// Larger
@media only screen and (min-width: $intermediate-size) {
	body {
		.header {
			h2 {font-size: 3em;}
			.network, a {width: 7vw;}
			a img {height: 3.4vw;}
			.network {font-size: 1em;}
		}
	} 
}


// COMPUTER

body {

	::-webkit-scrollbar {display: none;} /* Chrome, Safari and Opera */
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */

	height: 100vh;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	font-family: 'Ubuntu';
	color: rgb(232, 232, 232);
	::selection {
		color: black;
		background: #ad7fea;
	}

	background:linear-gradient(#21182c, #000000);
	.tx-link {
		color: rgb(255, 0, 238);
	}

	#root {
		height: 100%;
		overflow-y: scroll;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

	}
	.container .Toastify .Toastify__toast-container .Toastify__toast div.Toastify__toast-body div:nth-child(2) p {
		overflow: visible;
		word-wrap: break-word;
	}
	.header {

		padding: 2vw 3vw;

		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		a {
			padding: 0;
			margin: 0;
			img {
				transition: all .5s ease-in-out;
				&:hover {
					transform: rotate(180deg) scale(1.3);
				}
			}
		}

		h2 {
			font-weight: bold;
			margin: 0 1vw 0 1vw;
			text-align: center;
		}

		.network {
			text-align: center;

			padding: 1vh;
			background-color: #7000FF;
			color: white;
			border-radius: 7px;
			font-weight: bold;

			text-decoration: none;

			&:hover {
				background-color: #8f3aff;
				cursor: pointer;
			}
		}
	}

	.footer {
		// position: absolute;
		// bottom: 0;
		text-align: center;
		a {
			text-decoration: none;
			color: #a662ff;
		}
		width: 100%;
		margin-top: 5vh;
	}

	// BUTTONS

	.button {
		font-size: 1rem;
		padding: 0.7vh 2.5vh;
		border-radius: 11px;
		font-weight: 700;		
		border-width: 0;
		transition: all .4s ease-in-out;
		&:hover {
			cursor: pointer;
			animation: animatename 1s linear infinite;
		  }
	}

	.purple-button {
		background-color: #7000FF;
		color: white;
		&:hover {
		  background-color: rgb(126, 27, 255);
		}
	}

	.purple-button-e {
		background-color: #ffffff00;
		color: rgb(126, 27, 255);
		border: solid 2px rgb(126, 27, 255);
		&:hover {
			background-color: #7000FF;
			color: white;
		  }
	}

	.red-button {
		background-color: red;
		color: white;
		&:hover {
		  background-color: rgb(255, 54, 54);
		}
	}

	.red-button-e {
		background-color: #ffffff00;
		color: red;
		border: solid 2px red;
		&:hover {
			background-color: red;
			color: white;
		  }
	}

	.green-button {
		background-color: rgb(0, 169, 0);
		color: white;
		&:hover {
		  background-color: rgb(0, 169, 0);
		}
	}

	.green-button-e {
		background-color: #ffffff00;
		color: rgb(0, 169, 0);
		border: solid 2px rgb(0, 169, 0);
		&:hover {
			background-color: rgb(0, 169, 0);
			color: white;
		  }
	}
}
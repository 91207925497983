.modal {
    position: absolute;
    top: 5vh;
    background-color: transparent;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.792);
    display: flex;
    justify-content: center;
  }
// RESPONSIVE
$phone-size: 700px;
$intermediate-size: 1200px;

// Phone
@media only screen and (max-width: $phone-size) {
	.container {
    .main-panel .panel-section {
      h3 {font-size: 1.2em;}
      .panel-element {width: 85vw; margin-top: 2vh;}
      .transfer {
        flex-direction: column!important;
        .button {margin-top: 2vh; width: 100%;}
      }
    }
    .home-container {
      .explanations {
        width: 65vw;
        h2 {font-size: 1.2em;}
        p , ul {font-size: 0.8em}
        p , ul {line-height: 1.1rem!important;}
        ul {padding-left: 2vh;}
      }
      .wallet-connection {
        padding: 5vw!important;
        width: 70vw;
        h3 {font-size: 0.9em; margin: 0 0 5vw 0!important;}
        .wallet-adapter-button {
          height: 35px;
          padding: 0 3vw;
          font-size: 0.8em;
          i img {height: 20px ; width: 20px}
        } 
      }
    }
  } 
}

// transfer intermediate
@media only screen and (min-width: $phone-size) and (max-width: 800px) {
	.container .main-panel .panel-section .transfer {
    flex-direction: column!important;
    .button {margin-top: 2vh; width: 100%;}
  }

}

// intermediate
@media only screen and (min-width: $phone-size) and (max-width: $intermediate-size) {
	.container {
    .main-panel .panel-section {
      h3 {font-size: 1.4em;}
      .panel-element {width: 85vw; margin-top: 2vh;}
    }
    .home-container {
      .explanations {
        width: 40vw;
        h2 {font-size: 1.5em;}
        p , ul {font-size: 1em}
        p , ul {line-height: 1.2rem!important;}
        ul {padding-left: 4vh;}
      }
      .wallet-connection {
        padding: 5vw!important;
        h3 {font-size: 1.2em; margin: 0 0 5vw 0!important;}
      }
    }
  } 
}

// Larger
@media only screen and (min-width: $intermediate-size) {
	.container {
    .main-panel .panel-section {
      h3 {font-size: 1.4em;}
      .panel-element {width: 55vw; margin-top: 2vh;}
    }
    .home-container {
      .explanations {
        width: 35vw;
        h2 {font-size: 1.5em;}
        p , ul {font-size: 1.1em}
        ul {padding-left: 4vh;}
      }
      .wallet-connection {
        h3 {font-size: 1.3em}
      }
    }
  } 
}

// GENERAL
.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(33, 33, 33);
  padding: 2vh;
  border-radius: 11px;
}



.container {

  display: flex;
  justify-content: center;
  align-items: center;

  .main-panel {

    margin-top: 5vh;

    .panel-section {
      margin-bottom: 5vh;

      h3 {
        margin: 0 0 1vh 0;
        display: flex;
        justify-content: space-between;
      }

      .panel-element {
        background-color: rgb(33, 33, 33);
        padding: 2vh;
        border-radius: 11px;
        p {
          margin: 0.5vh 0;
        }
      }

      .panel-transfers {
        .no-transfer {
          color: rgb(147, 147, 147);
          font-weight: bold;
        }

        .transfer-list {

          .transfer {
            padding-top: 1vh;
            padding-bottom: 1vh;
            margin-bottom: 1vh;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            transition: all .2s ease-in-out;
            &:hover {
              cursor: pointer;
              transform: scale(1.05);
            }

            .no-action {
              color: rgb(147, 147, 147);
              font-weight: bold;
              padding: 0 2vh;
            }

            .positive-amount, .negative-amount {
              font-weight: bold;
              font-size: 1.2rem;
              padding: 0 1vh;
            }
            .positive-amount {
              color: rgb(0, 169, 0);
            }
            .negative-amount {
              color: red;
            }
          }
        }
      }
    }

    .panel-info {
      display: flex;
      flex-direction: column;

      p {
        margin: 0.5vh 0;
        span {
          word-wrap: break-word;
        }
        // display: flex;
        // justify-content: space-between;
      }
    }
  }

  .home-container {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .explanations {
      margin-top: 5vh;
      padding: 3vh;
      border: solid 1px rgb(147, 147, 147);
      border-radius: 20px;
      h2 {
        margin-top: 0;
      }
      p {
        line-height: 1.5rem;
      }
      ul li {
        margin-bottom: 0.7vw;
      }
    }

    .wallet-connection {

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  
      background-color: rgb(33, 33, 33);
      margin-top: 5vh;
      padding: 4vh 3vh;
  
      border-radius: 20px;

      .wallet-adapter-button {
        background-color: #7000FF;
      }
  
      h3 {
        text-align: center;
        align-self: center;
        margin: 0 5% 5% 5%;
      }
    }
  }


}


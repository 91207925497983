// RESPONSIVE
$phone-size: 700px;
$intermediate-size: 1200px;

// Phone + intermediate
@media only screen and (max-width: $intermediate-size) {
    .transfer-detail-container {width: 80vw;}
}

// Larger
@media only screen and (min-width: $intermediate-size) {
    .transfer-detail-container {width: auto;}
}

.transfer-detail-container {
    padding: 3vh;
    display: flex;
    flex-direction: column;
    border-radius: 11px;
    background-color: rgb(33,33,33);
    border-radius: 25px;

    .transfer-detail-title {
        font-size: 1.7em;
        text-align: center;
        margin: 0;
        font-weight: bold;
    }

    .all-info-container {
        margin: 4vh 0 4vh 0;
        display: flex;
        flex-direction: column;

        .info-container {
            margin-top: 3vh;
            display: flex;
            flex-direction: column; 
            h3 {
                margin: 0;
                font-weight: bold;
            }
            p {
                word-wrap: break-word;
                margin: 0.4vw 0 0 0;
            }
        }
    }
 
    .buttons-container {
        margin-top: 3vw;
        display: flex;
        button {
            width: 100%; 
        }
    }

}


